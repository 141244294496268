/* Main.css */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.header {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  text-align: center;
  margin-bottom: 30px;
  top: 0;
  background-color: white; /* or whatever color your header is */
  z-index: 1000; /* make sure it's on top of other content */
}


.header h1 {
  font-size: 36px;
  color: #333;
}

.header p {
  font-size: 18px;
  color: #666;
}

.courseContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.courseBox {
  padding: 30px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.courseBox:hover {
  transform: translateY(-5px);
}

.icon {
  font-size: 48px;
  color: #007bff;
  margin-bottom: 20px;
}

.courseBox h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.courseBox p {
  font-size: 16px;
  color: #666;
}

.courseBox a {
  display: block;
  margin-top: 20px;
  color: #007bff;
  text-decoration: none;
}

.courseBox a:hover {
  text-decoration: underline;
}

.container5 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.container6 {
  max-width: auto;
  margin: 0 auto;
  padding: 20px;
}

@media (max-width: 768px) {
  .courseContainer {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    grid-gap: -10px; /* this can be set to 0 if you only want space between the first and second boxes */
  }

  .courseBox {
    margin: 0; /* reset all margins for courseBox */
    margin-right: -10px;
    margin-left: -5px;
  }

  .container, .container5 {
    padding: 0 10px; /* add padding to the container */
  }
}
